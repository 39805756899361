define("give-public/controllers/confirmation", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    utilities: Ember.inject.service(),
    isShowingModal: false,
    router: Ember.inject.service(),
    cart: Ember.inject.service(),
    biggivePhase: Ember.inject.service(),
    vip: Ember.inject.service(),
    bigmatch: Ember.inject.service(),
    showAddingToCalendar: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      this.cart.empty();
      var utilities = this.get('utilities');
      var gift_id = window.localStorage.getItem('gift_id');
      // let gift_id = 2387;

      if (gift_id) {
        this.set('gift_id', gift_id);
        var app_host = _environment.default.APP.HOST;
        this.set('app_host', app_host);
        utilities.getTransactionResult(gift_id).then(function (data) {
          _this.set('receipt', data);
          if (data.tracking && Array.isArray(data.tracking) && data.tracking.length > 0) {
            data.tracking.find(function (tracking_data_obj) {
              if (tracking_data_obj.code === 'ac') {
                if (tracking_data_obj.value === '92723') {
                  _this.vip.loadAppealCode(tracking_data_obj.value);
                  var showAddingToCalendar = _this.vip.isVipStatus(_this.biggivePhase.currentPhase);
                  _this.set('showAddingToCalendar', showAddingToCalendar);
                }
                if (tracking_data_obj.value === '92724') {
                  _this.bigmatch.loadAppealCode(tracking_data_obj.value);
                  var _showAddingToCalendar = _this.bigmatch.isBigMatchStatus(_this.biggivePhase.currentPhase);
                  _this.set('showAddingToCalendar', _showAddingToCalendar);
                }
              }
            });
          }
          if (data.content) {
            if (data.content.image) {
              _this.set('hero_image', data.content.image);
            }
            if (data.content.header) {
              _this.set('content_header', data.content.header);
            }
            if (data.content.message) {
              _this.set('content_message', data.content.message);
            }
          }
          var total = _this.calculateTotal(data.basket.items);
          _this.set('total', total);
          var current_date = new Date(data.current_date);
          var payment_date = current_date.getDate();
          _this.set('payment_date', payment_date);
          if (data.is_recurring) {
            _this.set('isRecurring', true);
            _this.set('stop_month', data.frequency.stop_month);
            _this.set('stop_year', data.frequency.stop_year);
            if (data.frequency.frequency_type === 'monthly') {
              _this.set('frequency_type', 'month');
              _this.set('frequency', 'monthly');
            } else if (data.frequency.frequency_type === 'quarterly') {
              _this.set('frequency_type', 'quarter');
              _this.set('frequency', 'quarterly');
            } else {
              _this.set('frequency_type', 'year');
              _this.set('frequency', 'annual');
            }
            if (data.installments && Array.isArray(data.installments) && data.installments.length > 0) {
              _this.set('installments', data.installments.length);
              var grand_total = _this.get('total') * _this.get('installments');
              _this.set('grand_total', grand_total);
            }
          } else {
            _this.set('isRecurring', false);
          }
          window.localStorage.removeItem('cart');
          _this.trackGoogleEvent(data);
        });
      }
    },
    trackGoogleEvent: function trackGoogleEvent(data) {
      var items = data.basket.items.map(function (item) {
        var cart_item = {};
        cart_item.amount_of_donation = item.amount;
        cart_item.name = item.fund.title;
        return cart_item;
      });
    },
    calculateTotal: function calculateTotal(items) {
      var total = items.reduce(function (accumulator, item) {
        var amount = parseFloat(item.amount);
        return accumulator + amount;
      }, 0);
      return total;
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      selectResult: function selectResult(result) {
        this.transitionToRoute('fund', result);
      },
      goToFund: function goToFund(item) {
        this.router.transitionTo('fund', item.fund.fund_id);
      }
    }
  });
  _exports.default = _default;
});